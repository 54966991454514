// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { Shipment } from '../_models/shipment.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum ShipmentActionTypes {
  AllShipmentsRequested = '[Shipments Module] All Shipments Requested',
  AllShipmentsLoaded = '[Shipments API] All Shipments Loaded',
  ShipmentsPageRequested = '[Shipments List Page] Shipments Page Requested',
  ShipmentsPageLoaded = '[Shipments API] Shipments Page Loaded',
  ShipmentsPageCancelled = '[Shipments API] Shipments Page Cancelled',
  ShipmentsPageToggleLoading = '[Shipments] Shipments Page Toggle Loading',
  ShipmentsActionToggleLoading = '[Shipments] Shipments Action Toggle Loading',
  ShipmentCreate = '[Shipments API] Shipment Create',
  ShipmentUpdate = '[Shipments API] Shipment Update',
  ShipmentDelete = '[Shipments API] Shipment Delete',
  ShipmentMassDelete = '[Shipments API] Shipment Delete Many',
  ShipmentApprove = '[Shipments API] Shipment Approve',
  ShipmentMassApprove = '[Shipments API] Shipment Approve Many',
  ShipmentUpsert = '[Shipments] Update or Insert',
  MarkReturned = '[Shipments API] Mark shipment as RETURN',
  ShipmentMassUpsert = '[Shipments] Mass Upsert Shipments',
  ShipmentRemove = '[Shipments] Remove',
  ShipmentMassRemove = '[Shipments] Remove Many',
  ActionSuccess = '[Shipments] Last action success',
  ActionFail = '[Shipments] Last action failed',
  FetchVoucher = '[Shipments API] Shipment Fetch Voucher',
  ActionRefresh = '[Shipments] Shipments Page Refresh due to action',
  FetchShipment = '[Shipments API] Fetch Shipment from Server',
  FetchedShipment = '[Shipments] Fetched Shipment from Server',
  ShipmentCheckWeight = '[Shipments API] Check shipment weight with ELTA',
  ShipmentMassCheckWeight = '[Shipments API] Check shipments weight with ELTA',
  MarkPaid = '[Shipments API] Mark shipment as PAID',
  MarkPending = '[Shipments API] Mark shipment as PENDING',
  MarkClarification = '[Shipments API] Mark shipment as CLARIFICATION',
  MarkPaidApproved = '[Shipments API] Mark shipment as PAID_APPROVED',
  MarkInvoiced = '[Shipments API] Mark shipment as INVOICED',
  MarkProcessed = '[Shipments API] Mark shipment as PROCESSED',
  MassMarkPaid = '[Shipments API] Mark shipments as PAID',
  MassMarkPaidApproved = '[Shipments API] Mark shipments as PAID_APPROVED',
  MassMarkInvoiced = '[Shipments API] Mark shipments as INVOICED',
  MassMarkProcessed = '[Shipments API] Mark shipments as PROCESSED',
  MarkPrepaid = '[Shipments API] Mark shipment as prepaid',
  UnMarkPrepaid = '[Shipments API] UnMark shipment as prepaid',
  MarkPrereturn = '[Shipments API] Mark shipment as prereturn',
  UnMarkPrereturn = '[Shipments API] UnMark shipment as prereturn',
  MassMarkPrepaid = '[Shipments API] Mark shipments as prepaid',
  MassMarkCancellationPending = '[Shipments API] Mark shipments as cancellation pending',
  MarkAsPaidByXLS = '[Shipments API] Mark shipments as paid by XLS',
  MarkAsPaidByXLS_ELTA2 = '[Shipments API] Mark shipments as paid by XLS for ELTA2',
  MarkAsPaidByXLS_SPDX = '[Shipments API] Mark shipments as paid by XLS for SPEEDEX',
  MarkAsPaidByXLS_TAXY = '[Shipments API] Mark shipments as paid by XLS for TAXYDEMA/DIAKINISIS',
  MarkAsPaidByXLS_ACS = '[Shipments API] Mark shipments as paid by XLS for ACS',
  MarkAsPaidByXLS_CS = '[Shipments API] Mark shipments as paid by XLS for CS',
  MarkAsPaidByXLS_GENIKI = '[Shipments API] Mark shipments as paid by XLS for GENIKI',
  MarkAsApprovedByXLS_GENIKI = '[Shipments API] Mark shipments as approved by XLS for GENIKI',
  CheckKgByXLS = '[Shipments API] Check Kg by XLS for SPEEDEX',
  CheckKgByXLS_ACS = '[Shipments API] Check Kg by XLS for ACS',
  ValidateSPDX = '[Shipments API] Validate shipments by XLS for SPEEDEX',
  SendDeliveredXLS = '[Shipments API] Check Send Delivered via XLS',
  ReturnDeliveredXLS = '[Shipments API] Check Return Delivered via XLS',
  AddByXLS = '[Shipments API] Add by XLS',
  SError = '[Shipments] Status error for xls',
  EError = '[Shipments] Extra shipments error for xls',
  AError = '[Shipments] Extra shipments already exist error for xls',
  UNFError = '[Shipments] Extra shipments user does not exist error for xls',
  MAError = '[Shipments] Extra shipments error for xls mass add',
  CSError = '[Shipments] Corrected cod ammount for shipments',
  ReturnCreate = '[Shipments API] Create a return shipment',
  ManualReturnCreate = '[Shipments API] Create a return shipment manually',
  MarkReady = '[Shipments API] Mark shipment as ready',
  UnMarkReady = '[Shipments API] UnMark shipment as ready',
  MassMarkReady = '[Shipments API] Mark shipments as ready',
  MassUnMarkReady = '[Shipments API] UnMark shipments as ready',
  GotVoucher = '[Shipmnets] Got Voucher from server',
  Liquidate = '[Shipments API] Liquidation documents',
  MarkCancellationPending = '[Shipments API] Mark shipment for cancellation',
  SetListFilters = '[Shipments] Set filters for list grid',
  SetUserFilters = '[Shipments] Set filters for user grid',
  SetFinancialFilters = '[Shipments] Set filters for financial grid',
  SetUserFinancialFilters = '[Shipments] Set filters for financial grid user view',
  ToggleReturnDelivered = '[Shipments API] Toggle Return Delivered',
  ToggleSendDelivered = '[Shipments API] Toggle Send Delivered',
  CheckReturned = '[Shipments API] Check Returned',
  ShipmentCancel = '[Shipments API] Cancel Shipment',
  CreateAutoReturn = '[Shipments API] Create Return',
}

export class SetListFilters implements Action {
  readonly type = ShipmentActionTypes.SetListFilters;
  constructor(public payload: { filters: any }) {}
}

export class SetUserFilters implements Action {
  readonly type = ShipmentActionTypes.SetUserFilters;
  constructor(public payload: { filters: any }) {}
}

export class SetFinancialFilters implements Action {
  readonly type = ShipmentActionTypes.SetFinancialFilters;
  constructor(public payload: { filters: any }) {}
}

export class SetUserFinancialFilters implements Action {
  readonly type = ShipmentActionTypes.SetUserFinancialFilters;
  constructor(public payload: { filters: any }) {}
}

export class Liquidate implements Action {
  readonly type = ShipmentActionTypes.Liquidate;
  constructor(public payload: { fdata: any }) {}
}

export class GotVoucher implements Action {
  readonly type = ShipmentActionTypes.GotVoucher;
}

export class MarkReturned implements Action {
  readonly type = ShipmentActionTypes.MarkReturned;
  constructor(public payload: { shipment: Shipment }) {}
}

export class CreateAutoReturn implements Action {
  readonly type = ShipmentActionTypes.CreateAutoReturn;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkCancellationPending implements Action {
  readonly type = ShipmentActionTypes.MarkCancellationPending;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ToggleReturnDelivered implements Action {
  readonly type = ShipmentActionTypes.ToggleReturnDelivered;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ToggleSendDelivered implements Action {
  readonly type = ShipmentActionTypes.ToggleSendDelivered;
  constructor(public payload: { shipment: Shipment }) {}
}

export class CheckReturned implements Action {
  readonly type = ShipmentActionTypes.CheckReturned;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentCancel implements Action {
  readonly type = ShipmentActionTypes.ShipmentCancel;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkReady implements Action {
  readonly type = ShipmentActionTypes.MarkReady;
  constructor(public payload: { shipment: Shipment }) {}
}

export class UnMarkReady implements Action {
  readonly type = ShipmentActionTypes.UnMarkReady;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MassMarkReady implements Action {
  readonly type = ShipmentActionTypes.MassMarkReady;
  constructor(public payload: { ids: Array<number> }) {}
}

export class MassUnMarkReady implements Action {
  readonly type = ShipmentActionTypes.MassUnMarkReady;
  constructor(public payload: { ids: Array<number> }) {}
}

export class ReturnCreate implements Action {
  readonly type = ShipmentActionTypes.ReturnCreate;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ManualReturnCreate implements Action {
  readonly type = ShipmentActionTypes.ManualReturnCreate;
  constructor(public payload: { shipment: Shipment }) {}
}

export class CSError implements Action {
  readonly type = ShipmentActionTypes.CSError;
  constructor(public payload: { result: any }) {}
}

export class SError implements Action {
  readonly type = ShipmentActionTypes.SError;
  constructor(public payload: { result: any }) {}
}

export class EError implements Action {
  readonly type = ShipmentActionTypes.EError;
  constructor(public payload: { result: any }) {}
}

export class AError implements Action {
  readonly type = ShipmentActionTypes.AError;
  constructor(public payload: { result: any }) {}
}

export class UNFError implements Action {
  readonly type = ShipmentActionTypes.UNFError;
  constructor(public payload: { result: any }) {}
}

export class MAError implements Action {
  readonly type = ShipmentActionTypes.MAError;
  constructor(public payload: { result: any }) {}
}

export class MarkAsPaidByXLS implements Action {
  readonly type = ShipmentActionTypes.MarkAsPaidByXLS;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkAsPaidByXLS_ELTA2 implements Action {
  readonly type = ShipmentActionTypes.MarkAsPaidByXLS_ELTA2;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkAsPaidByXLS_SPDX implements Action {
  readonly type = ShipmentActionTypes.MarkAsPaidByXLS_SPDX;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkAsPaidByXLS_TAXY implements Action {
  readonly type = ShipmentActionTypes.MarkAsPaidByXLS_TAXY;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkAsPaidByXLS_ACS implements Action {
  readonly type = ShipmentActionTypes.MarkAsPaidByXLS_ACS;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkAsPaidByXLS_CS implements Action {
  readonly type = ShipmentActionTypes.MarkAsPaidByXLS_CS;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkAsApprovedByXLS_GENIKI implements Action {
  readonly type = ShipmentActionTypes.MarkAsApprovedByXLS_GENIKI;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkAsPaidByXLS_GENIKI implements Action {
  readonly type = ShipmentActionTypes.MarkAsPaidByXLS_GENIKI;
  constructor(public payload: { items: Array<any> }) {}
}

export class CheckKgByXLS implements Action {
  readonly type = ShipmentActionTypes.CheckKgByXLS;
  constructor(public payload: { items: Array<any> }) {}
}

export class CheckKgByXLS_ACS implements Action {
  readonly type = ShipmentActionTypes.CheckKgByXLS_ACS;
  constructor(public payload: { items: Array<any> }) {}
}

export class ValidateSPDX implements Action {
  readonly type = ShipmentActionTypes.ValidateSPDX;
  constructor(public payload: { items: Array<any> }) {}
}

export class SendDeliveredXLS implements Action {
  readonly type = ShipmentActionTypes.SendDeliveredXLS;
  constructor(public payload: { items: Array<any> }) {}
}

export class ReturnDeliveredXLS implements Action {
  readonly type = ShipmentActionTypes.ReturnDeliveredXLS;
  constructor(public payload: { items: Array<any> }) {}
}

export class AddByXLS implements Action {
  readonly type = ShipmentActionTypes.AddByXLS;
  constructor(public payload: { items: Array<any> }) {}
}

export class MarkPrepaid implements Action {
  readonly type = ShipmentActionTypes.MarkPrepaid;
  constructor(public payload: { shipment: Shipment }) {}
}

export class UnMarkPrepaid implements Action {
  readonly type = ShipmentActionTypes.UnMarkPrepaid;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkPrereturn implements Action {
  readonly type = ShipmentActionTypes.MarkPrereturn;
  constructor(public payload: { shipment: Shipment }) {}
}

export class UnMarkPrereturn implements Action {
  readonly type = ShipmentActionTypes.UnMarkPrereturn;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MassMarkPrepaid implements Action {
  readonly type = ShipmentActionTypes.MassMarkPrepaid;
  constructor(public payload: { ids: Array<number> }) {}
}

export class MassMarkCancellationPending implements Action {
  readonly type = ShipmentActionTypes.MassMarkCancellationPending;
  constructor(public payload: { ids: Array<number> }) {}
}

export class MarkProcessed implements Action {
  readonly type = ShipmentActionTypes.MarkProcessed;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkPaid implements Action {
  readonly type = ShipmentActionTypes.MarkPaid;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkPending implements Action {
  readonly type = ShipmentActionTypes.MarkPending;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkClarification implements Action {
  readonly type = ShipmentActionTypes.MarkClarification;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkPaidApproved implements Action {
  readonly type = ShipmentActionTypes.MarkPaidApproved;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MarkInvoiced implements Action {
  readonly type = ShipmentActionTypes.MarkInvoiced;
  constructor(public payload: { shipment: Shipment }) {}
}

export class MassMarkProcessed implements Action {
  readonly type = ShipmentActionTypes.MassMarkProcessed;
  constructor(public payload: { ids: Array<number> }) {}
}

export class MassMarkPaid implements Action {
  readonly type = ShipmentActionTypes.MassMarkPaid;
  constructor(public payload: { ids: Array<number> }) {}
}

export class MassMarkPaidApproved implements Action {
  readonly type = ShipmentActionTypes.MassMarkPaidApproved;
  constructor(public payload: { ids: Array<number> }) {}
}

export class MassMarkInvoiced implements Action {
  readonly type = ShipmentActionTypes.MassMarkInvoiced;
  constructor(public payload: { ids: Array<number> }) {}
}

export class ShipmentCheckWeight implements Action {
  readonly type = ShipmentActionTypes.ShipmentCheckWeight;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentMassCheckWeight implements Action {
  readonly type = ShipmentActionTypes.ShipmentMassCheckWeight;
  constructor(public payload: { ids: Array<number> }) {}
}

export class FetchedShipment implements Action {
  readonly type = ShipmentActionTypes.FetchedShipment;
}

export class FetchShipment implements Action {
  readonly type = ShipmentActionTypes.FetchShipment;
  constructor(public payload: { id: number }) {}
}

export class ActionRefresh implements Action {
  readonly type = ShipmentActionTypes.ActionRefresh;
  constructor(public payload: { result: any }) {}
}

export class ActionSuccess implements Action {
  readonly type = ShipmentActionTypes.ActionSuccess;
  constructor(public payload: { result: any }) {}
}

export class ActionFail implements Action {
  readonly type = ShipmentActionTypes.ActionFail;
  constructor(public payload: { result: any }) {}
}

export class ShipmentCreate implements Action {
  readonly type = ShipmentActionTypes.ShipmentCreate;
  constructor(public payload: { shipment: Shipment }) {}
}

export class FetchVoucher implements Action {
  readonly type = ShipmentActionTypes.FetchVoucher;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentDelete implements Action {
  readonly type = ShipmentActionTypes.ShipmentDelete;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentApprove implements Action {
  readonly type = ShipmentActionTypes.ShipmentApprove;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentMassApprove implements Action {
  readonly type = ShipmentActionTypes.ShipmentMassApprove;
  constructor(public payload: { ids: Array<number> }) {}
}

export class ShipmentMassUpsert implements Action {
  readonly type = ShipmentActionTypes.ShipmentMassUpsert;
  constructor(public payload: { shipments: Array<Shipment> }) {}
}

export class ShipmentMassDelete implements Action {
  readonly type = ShipmentActionTypes.ShipmentMassDelete;
  constructor(public payload: { ids: Array<number> }) {}
}

export class ShipmentMassRemove implements Action {
  readonly type = ShipmentActionTypes.ShipmentMassRemove;
  constructor(public payload: { ids: Array<number> }) {}
}

export class ShipmentUpdate implements Action {
  readonly type = ShipmentActionTypes.ShipmentUpdate;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentUpsert implements Action {
  readonly type = ShipmentActionTypes.ShipmentUpsert;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentRemove implements Action {
  readonly type = ShipmentActionTypes.ShipmentRemove;
  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentsPageRequested implements Action {
  readonly type = ShipmentActionTypes.ShipmentsPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class ShipmentsPageLoaded implements Action {
  readonly type = ShipmentActionTypes.ShipmentsPageLoaded;
  constructor(
    public payload: {
      shipments: Shipment[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class ShipmentsPageCancelled implements Action {
  readonly type = ShipmentActionTypes.ShipmentsPageCancelled;
}

export class ShipmentsPageToggleLoading implements Action {
  readonly type = ShipmentActionTypes.ShipmentsPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class ShipmentsActionToggleLoading implements Action {
  readonly type = ShipmentActionTypes.ShipmentsActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type ShipmentActions =
  | ShipmentsPageLoaded
  | ShipmentsPageCancelled
  | ShipmentsPageToggleLoading
  | ShipmentsPageRequested
  | ShipmentsActionToggleLoading
  | ActionFail
  | ActionSuccess
  | ActionRefresh
  | ShipmentUpsert
  | ShipmentUpdate
  | ShipmentDelete
  | ShipmentRemove
  | ShipmentMassDelete
  | ShipmentMassRemove
  | ShipmentMassApprove
  | ShipmentMassUpsert
  | ShipmentApprove
  | FetchVoucher
  | ShipmentCreate
  | FetchedShipment
  | FetchShipment
  | ShipmentCheckWeight
  | ShipmentMassCheckWeight
  | MarkPaid
  | MarkPending
  | MarkClarification
  | MarkPaidApproved
  | MarkInvoiced
  | MarkProcessed
  | MassMarkPaid
  | MassMarkCancellationPending
  | MassMarkPaidApproved
  | MassMarkInvoiced
  | MassMarkProcessed
  | MarkPrepaid
  | UnMarkPrepaid
  | MarkPrereturn
  | UnMarkPrereturn
  | MassMarkPrepaid
  | MarkAsPaidByXLS
  | MarkAsPaidByXLS_ELTA2
  | MarkAsPaidByXLS_SPDX
  | MarkAsPaidByXLS_CS
  | MarkAsPaidByXLS_TAXY
  | MarkAsPaidByXLS_ACS
  | MarkAsPaidByXLS_GENIKI
  | MarkAsApprovedByXLS_GENIKI
  | CheckKgByXLS
  | CheckKgByXLS_ACS
  | ValidateSPDX
  | SendDeliveredXLS
  | ReturnDeliveredXLS
  | SError
  | EError
  | AError
  | UNFError
  | MAError
  | CSError
  | ReturnCreate
  | ManualReturnCreate
  | MarkReady
  | UnMarkReady
  | MassMarkReady
  | MassUnMarkReady
  | GotVoucher
  | Liquidate
  | MarkCancellationPending
  | SetListFilters
  | SetUserFilters
  | SetFinancialFilters
  | SetUserFinancialFilters
  | AddByXLS
  | ToggleReturnDelivered
  | ToggleSendDelivered
  | CheckReturned
  | MarkReturned
  | CreateAutoReturn
  | ShipmentCancel;
