<form [formGroup]="diakForm" class="kt-form kt-form--group-seperator-dashed">
  <kt-alert
    *ngIf="hasFormErrors"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    Τα στοιχεία που εισάγατε δεν είναι σωστά. Προσπαθήστε ξανά.
  </kt-alert>
  <kt-alert
    *ngIf="serverError | async as err"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    {{ err }}
  </kt-alert>
  <div class="kt-form__section kt-form__section--first">
    <div class="form-group kt-form__group">
      <div class="row mb-5">
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Account Code" formControlName="diak_account_code" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>Account Code</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="User Alias" formControlName="diak_user_alias" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>User Alias</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              placeholder="Credential Value"
              formControlName="diak_credential_value"
            />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>Credential Value.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-4 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="API Key" formControlName="diak_api_key" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>API Key σας.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-8 kt-margin-bottom-20-mobile plr">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="API Endpoint" formControlName="diak_api_endpoint" />
            <mat-error
              >Το πεδίο αυτό είναι
              <strong>απαραίτητο</strong>
            </mat-error>
            <mat-hint align="start"
              >Παρακαλώ ειστάγετε το
              <strong>API Endpoint.</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
