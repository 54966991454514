import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Shipment } from '../_models/shipment.model';
import { Status } from '../_models/status.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';

@Injectable()
export class ShipmentService {
  constructor(private http: HttpClient) {}

  getAllShipments(): Observable<Shipment[]> {
    return this.http.get<Shipment[]>('api/shipments/');
  }

  getStatus(item: Shipment): Observable<Status[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<Status[]>(`api/shipments/${item.id}/get_status/`, {
      headers: httpHeaders,
    });
  }

  findShipments(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      'api/shipments/find/',
      queryParams,
      { headers: httpHeaders },
    );
  }

  createShipment(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Shipment>('api/shipments/', shipment, {
      headers: httpHeaders,
    });
  }

  createPickupOrder(data: any): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Shipment>(
      `api/shipments/${data.sid}/create_pickup_order/`,
      data,
      { headers: httpHeaders },
    );
  }

  reschedulePickupOrder(data: any): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Shipment>(
      `api/shipments/${data.sid}/reschedule_pickup_order/`,
      data,
      { headers: httpHeaders },
    );
  }

  cancelPickupOrder(data: any): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Shipment>(
      `api/shipments/${data.sid}/cancel_pickup_order/`,
      data,
      { headers: httpHeaders },
    );
  }

  getPickupOrder(order_id: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>('api/shipments/get_pickup_order/', order_id, {
      headers: httpHeaders,
    });
  }

  createReturn(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Shipment>('api/shipments/create_return/', shipment, {
      headers: httpHeaders,
    });
  }

  manualCreateReturn(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Shipment>(
      'api/shipments/manual_create_return/',
      shipment,
      { headers: httpHeaders },
    );
  }

  fetchShipment(id: number): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${id}/`;
    return this.http.get<Shipment>(url);
  }

  updateShipment(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/`;
    return this.http.patch<Shipment>(url, shipment, { headers: httpHeaders });
  }

  deleteShipment(shipment: Shipment) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/`;
    return this.http.delete(url, { headers: httpHeaders });
  }

  deleteShipments(ids: Array<number>): Observable<Array<number>> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<any>(
        'api/shipments/mass_delete/',
        { ids: ids },
        { headers: httpHeaders },
      )
      .pipe(map((res) => res.ids));
  }

  approveShipment(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/approve/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markPaid(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_paid/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markPending(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_pending/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markClarification(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_clarification/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  massMarkPaid(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  massMarkCancellationPending(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_cancellation_pending/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  markPaidApproved(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_paid_approved/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  massMarkPaidApproved(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_approved/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  markInvoiced(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_invoiced/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markReady(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_ready/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  unmarkReady(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/unmark_ready/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  checkReturned(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/check_returned/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  shipmentCancel(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/cancel/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  massMarkReady(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_ready/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  massUnMarkReady(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_unmark_ready/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  markPrepaid(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_prepaid/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markCancellationPending(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_cancellation_pending/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  toggleReturnDelivered(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/toggle_return_delivered/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  toggleSendDelivered(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/toggle_send_delivered/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  unmarkPrepaid(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/unmark_prepaid/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markPrereturn(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_prereturn/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  unmarkPrereturn(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/unmark_prereturn/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  massMarkPrepaid(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_prepaid/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  markAsPaidByXLS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_by_xls/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  markAsPaidByXLS_ELTA2(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_by_xls_elta2/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  markAsPaidByXLS_SPDX(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_by_xls_spdx/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  markReturned(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_returned/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markAsPaidByXLS_TAXY(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_by_xls_taxy/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  markAsPaidByXLS_ACS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_by_xls_acs/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  markAsPaidByXLS_CS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_by_xls_cs/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  markAsApprovedByXLS_GENIKI(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_approved_by_xls_geniki/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  markAsPaidByXLS_GENIKI(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_paid_by_xls_geniki/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  checkKgByXLS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/check_kg_by_xls/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  checkKgByXLS_ACS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/check_kg_by_xls_acs/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  getRetDev(): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>('api/shipments/retdev/', {
      headers: httpHeaders,
    });
  }

  validateSPDX(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/validate_spdx/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  sendDeliveredXLS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/send_delivered_by_xls/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  returnDeliveredXLS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/return_delivered_by_xls/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  AddByXLS(items: Array<any>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/add_by_xls/',
      { items: items },
      { headers: httpHeaders },
    );
  }

  massMarkInvoiced(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_invoiced/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  createAutoReturn(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/auto_create_return/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  markProcessed(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/mark_processed/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  massMarkProcessed(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_mark_processed/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  checkWeight(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/check_kg/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  checkMassWeight(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_check_kg/',
      { ids: ids },
      { headers: httpHeaders },
    );
  }

  approveShipments(ids: Array<number>): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(
      'api/shipments/mass_approve/',
      { ids: ids },
      { headers: httpHeaders },
    );
    /*.pipe(
            map( res => res.items)
        );*/
  }

  fetchVoucher(shipment: Shipment): Observable<Shipment> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const url = `${'api/shipments'}/${shipment.id}/fetch_voucher/`;
    return this.http.get<Shipment>(url, { headers: httpHeaders });
  }

  liquidate(fd: any): Observable<any> {
    return this.http.post<any>('api/shipments/liquidation/', fd);
  }

  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
