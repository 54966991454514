<kt-portlet>
  <!-- PORTLET LOADING | Binded to TABLE Datasource -->
  <!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
  <kt-portlet-header
    [title]="'Αποστολές'"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="dataSource.loading$"
  >
    <ng-container ktPortletTools>
      <span class="sp"
        ><mat-spinner color="primary" *ngIf="isPrinting | async" diameter="25"></mat-spinner
      ></span>
      <a
        (click)="refs()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Ανανέωση Αποστολών"
      >
        <i class="la la-refresh"></i>
        <span class="kt-hidden-mobile">Ανανέωση</span>
      </a>
      <a
        (click)="clearFilters()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Αφαίρεση Φίλτρων"
      >
        <i class="far fa-calendar-minus"></i>
        <span class="kt-hidden-mobile">Καθαρισμός Φίλτρων</span>
      </a>
      <!-- PRINT BUTTON -->
      <button
        *ngIf="!adminView && (user$ | async)?.allow_add_by_xls"
        class="kt-margin-r-10"
        type="button"
        mat-raised-button
        color="warn"
        (click)="fileInput.click()"
        matTooltip="
                Εισαγωγή αποστολών απο Excel με την δομή. &#13;
                A: Ονοματεπώνυμο Παραλήπτη &#13;
                B: Διεύθυνση Παραλήπτη &#13;
                C: Ταχυδρομικός Κώδικας &#13;
                D: Περιοχή Παραλήπτη &#13;
                E: Τηλέφωνο Παραλήπτη &#13;
                F: 2o Τηλέφωνο Παραλήπτη &#13;
                G: Παρατηρήσεις &#13;
                H: Σχόλια &#13;
                I: Διάσταση Voucher (A6 / A4) &#13;
                J: Βάρος (Kg) &#13;
                K: Αριθμός Αντικειμένων &#13;
                L: Ποσό Αντικαταβολής ή Κενό &#13;
            "
        [matTooltipClass]="'brk-tooltip'"
      >
        Εισαγωγή από XLS
      </button>
      <!--button *ngIf="adminView" class="kt-margin-r-10" type="button" mat-raised-button color="warn" (click)="fileInput.click()" matTooltip="
                Εισαγωγή αποστολών απο Excel με την δομή. &#13;
                A: Ονοματεπώνυμο Παραλήπτη &#13;
                B: Διεύθυνση Παραλήπτη &#13;
                C: Ταχυδρομικός Κώδικας &#13;
                D: Περιοχή Παραλήπτη &#13;
                E: Τηλέφωνο Παραλήπτη &#13;
                F: 2o Τηλέφωνο Παραλήπτη &#13;
                G: Παρατηρήσεις &#13;
                H: Σχόλια &#13;
                I: Διάσταση Voucher (A6 / A4) &#13;
                J: Βάρος (Kg) &#13;
                K: Αριθμός Αντικειμένων &#13;
                L: Ποσό Αντικαταβολής ή Κενό &#13;
                M: Κωδικός Αριθμός Πελάτη &#13;
            " [matTooltipClass]="'brk-tooltip'">Εισαγωγή από XLS</button-->
      <input
        hidden
        (change)="onFileSelected($event)"
        #fileInput
        type="file"
        id="file"
        acccept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <button
        [routerLink]="['../shipments/add']"
        mat-raised-button
        color="primary"
        matTooltip="Δημιουργία Αποστολής"
      >
        Νέα Αποστολή
      </button>
      <button
        *ngIf="adminView"
        [routerLink]="['../shipments/add', { return: true }]"
        class="mlb"
        mat-raised-button
        color="warn"
        matTooltip="Δημιουργία Επιστροφής"
      >
        Νέα Επιστροφή
      </button>
      <button
        [routerLink]="['../shipments/create-return']"
        class="mlb newreturn"
        mat-raised-button
        matTooltip="Δημιουργία Επιστροφής"
      >
        Νέα Επιστροφή
      </button>
      <!-- Buttons (Material Angular) | See off.documenations 'https://material.angular.io/components/button/overview' -->
      <!-- mat-raised-button | Rectangular contained button w/ elevation  -->
    </ng-container>
  </kt-portlet-header>
  <!-- end::Header -->

  <!-- start::Body (attribute: ktPortletBody) -->
  <kt-portlet-body>
    <!-- start::FILTERS & GROUP ACTIONS -->
    <div class="row align-items-center">
      <div class="col-md-12 kt-margin-bottom-10-mobile">
        <kt-alert
          *ngIf="serverError | async as err"
          type="warn"
          [showCloseButton]="true"
          [duration]="60000"
          (close)="onAlertClose($event)"
        >
          {{ err }}
        </kt-alert>
      </div>
    </div>

    <div class="kt-form kt-margin-b-30">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Αναζήτηση Αποστολών </mat-panel-title>
          <mat-panel-description>
            Αναζήτηση αποστολών και με απλά και σύνθετα φίλτρα.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- start::FILTERS -->
        <div class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  matInput
                  placeholder="Αναζήτηση Παραλήπτη"
                  #searchInput
                  placeholder="Παραλήπτης"
                />
                <mat-hint align="start"> <strong>Αναζήτση</strong> με παραλήπτη </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Τηλέφωνο Παραλήπτη" #searchInputTel />
                <mat-hint align="start"> <strong>Αναζήτση</strong> με τηλέφωνο παραλήπτη </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-2 kt-margin-bottom-20-mobile">
              <mat-button-toggle
                (change)="codChanged($event)"
                class="cod-btn tbt"
                #codtoggle
                [checked]="ocod"
              >
                <i class="fas fa-euro-sign mri"></i>
                Αντικαταβολές
              </mat-button-toggle>
            </div>
            <div [hidden]="!codToggle.checked" class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  [textMask]="{ mask: curr_mask }"
                  (keyup)="fFA($event)"
                  class="right-align"
                  matInput
                  #searchInputFA
                  placeholder="Από ποσό ( &euro; 00.00 )"
                />
                <mat-hint align="start"> <strong>Από</strong> ποσό αντικαταβολής </mat-hint>
              </mat-form-field>
            </div>
            <div [hidden]="!codToggle.checked" class="col kt-margin-bottom-20-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <input
                  [textMask]="{ mask: curr_mask }"
                  (keyup)="fTA($event)"
                  class="right-align"
                  matInput
                  #searchInputTA
                  placeholder="Εώς ποσό ( &euro; 00.00 )"
                />
                <mat-hint align="start"> <strong>Εώς</strong> ποσό αντικαταβολής </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-7 kt-margin-bottom-20-mobile my-2">
              <mat-form-field class="mat-form-field-fluid">
                <!--input matInput placeholder="Αναζήτηση Κωδ. Αποστολής" #searchInputKA placeholder="Κωδ. Αποστολής"-->
                <mat-chip-list #vgcodeList aria-label="Κωδ. Αποστολής">
                  <mat-chip
                    class="fchip"
                    *ngFor="let code of fvg_codes"
                    selectable="false"
                    removable="true"
                    (removed)="vg_remove(code)"
                  >
                    {{ code }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="Κωδ. Αποστολής..."
                    [matChipInputFor]="vgcodeList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    matChipInputAddOnBlur="true"
                    (matChipInputTokenEnd)="vg_add($event)"
                  />
                </mat-chip-list>
                <mat-hint align="start"> <strong>Αναζήτση</strong> με Κωδ. Αποστολής </mat-hint>
              </mat-form-field>
            </div>
            <div class="col-4 kt-margin-bottom-20-mobile my-2">
              <mat-form-field class="mat-form-field-fluid">
                <mat-label>Επιλογή Courier</mat-label>
                <mat-select [(value)]="courier" (selectionChange)="courierChange($event)">
                  <mat-option>Οποιοδήποτε</mat-option>
                  <mat-option value="ELTA"> ΕΛΤΑ Courier </mat-option>
                  <mat-option value="ELTA2"> ΕΛΤΑ Courier 2 </mat-option>
                  <mat-option value="SPEEDEX"> SPEEDEX </mat-option>
                  <mat-option value="COURIER_CENTER"> COURIER CENTER </mat-option>
                  <mat-option value="TAXYDEMA"> ΤΑΧΥΔΕΜΑ </mat-option>
                  <mat-option value="DIAKINISIS"> DIAKINISIS </mat-option>
                  <mat-option value="GENIKI"> ΓΕΝΙΚΗ ΤΑΧΥΔΡΟΜΙΚΗ </mat-option>
                  <mat-option value="ACS1"> ACS1 </mat-option>
                  <mat-option value="ACS2"> ACS2 </mat-option>
                  <mat-option value="ACS3"> ACS3 </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  class="spend tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PENDING')"
                  value="PENDING"
                >
                  <i class="flaticon2-download mri aicon mri"></i>
                  Εκκρεμείς
                </mat-button-toggle>
                <mat-button-toggle
                  class="sappr tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PROCESSED')"
                  value="PROCESSED"
                >
                  <i class="flaticon2-black-back-closed-envelope-shape mri"></i>
                  Εγκεκριμένες
                </mat-button-toggle>
                <mat-button-toggle
                  *ngIf="adminView"
                  class="spaid tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PAID')"
                  value="PAID"
                >
                  <i class="fas fa-money-check-alt mri aicon"></i>
                  Εισπραγμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="spaida tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('PAID_APPROVED')"
                  value="PAID_APPROVED"
                >
                  <i class="fas fa-hand-holding-usd mri aicon"></i>
                  Προς Απόδοση
                </mat-button-toggle>
                <mat-button-toggle
                  class="sinv tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('INVOICED')"
                  value="INVOICED"
                >
                  <i class="fas fa-file-invoice-dollar mri aicon"></i>
                  Εξοφλημένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="sreturned tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('RETURNED')"
                  value="RETURNED"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancellation_pending tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CANCELLATION_PENDING')"
                  value="CANCELLATION_PENDING"
                >
                  <i class="fas fa-exclamation-circle mri aicon"></i>
                  Προς Ακύρωση
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancelled tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CANCELLED')"
                  value="CANCELLED"
                >
                  <i class="fas fa-times-circle mri aicon"></i>
                  Ακυρωμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="cancelled tbt"
                  (change)="fStatusChanged($event)"
                  [checked]="fStatus.includes('CLARIFICATION')"
                  value="CLARIFICATION"
                >
                  <i class="fas fa-question mri aicon"></i>
                  Προς Διασάφηση
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-button-toggle-group multiple name="status" aria-label="Κατάσταση">
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="readyStatusChanged($event)"
                  [checked]="ready"
                >
                  <i class="fas fa-paper-plane mri aicon"></i>
                  Έτοιμες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="oreadyStatusChanged($event)"
                  [checked]="oready"
                >
                  <i class="fas fa-paper-plane mri aicon"></i>
                  Μόνο Έτοιμες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="deliveredStatusChanged($event)"
                  [checked]="delivered"
                >
                  <i class="fas fa-envelope-open-text mri aicon"></i>
                  Παραδομένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="odeliveredStatusChanged($event)"
                  [checked]="odelivered"
                >
                  <i class="fas fa-envelope-open-text mri aicon"></i>
                  Μόνο Παραδομένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="returnedStatusChanged($event)"
                  [checked]="returned"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="oreturnedStatusChanged($event)"
                  [checked]="oreturned"
                >
                  <i class="fas fa-undo-alt mri aicon"></i>
                  Μόνο Επεστραμένες
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="odsrStatusChanged($event)"
                  [checked]="odsr"
                >
                  <i class="fas fas fa-shipping-fast mri aicon"></i>
                  Μόνο με Pickup Order
                </mat-button-toggle>
                <mat-button-toggle
                  class="prpaid tbt"
                  (change)="arsrStatusChanged($event)"
                  [checked]="arsr"
                >
                  <i class="fas fas fa-map mri aicon"></i>
                  Χωρίς Περιοχή
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row align-items-center mbr">
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFCe('change', $event)"
                  [(ngModel)]="dpFC"
                  [matDatepicker]="dpFCP"
                  placeholder="Δημιουργήθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFCP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFCP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία δημιουργίας </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTCe('change', $event)"
                  [(ngModel)]="dpTC"
                  [matDatepicker]="dpTCP"
                  placeholder="Δημιουργήθηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTCP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTCP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία δημιουργίας </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpFAe('change', $event)"
                  [(ngModel)]="dpFA"
                  [matDatepicker]="dpFAP"
                  placeholder="Εγκρίθηκε από"
                />
                <mat-datepicker-toggle matSuffix [for]="dpFAP"> </mat-datepicker-toggle>
                <mat-datepicker #dpFAP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία έγκρισης </mat-hint>
              </mat-form-field>
            </div>
            <div class="col kt-margin-bottom-20-mobile">
              <mat-form-field>
                <input
                  matInput
                  (dateChange)="dpTAe('change', $event)"
                  [(ngModel)]="dpTA"
                  [matDatepicker]="dpTAP"
                  placeholder="Εγκρίθηκε εώς"
                />
                <mat-datepicker-toggle matSuffix [for]="dpTAP"> </mat-datepicker-toggle>
                <mat-datepicker #dpTAP></mat-datepicker>
                <mat-hint align="start"> <strong>Από</strong> ημερομηνία έγκρισης </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="adminView" class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-md-12 kt-margin-bottom-10-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-chip-list #chipList aria-label="Επιλογή χρηστών">
                  <mat-chip
                    class="fchip"
                    *ngFor="let usr of fUsers"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="remove(usr)"
                  >
                    {{ usr.lastname }} {{ usr.firstname }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #usrInput
                    (keyup)="filterUsers($event)"
                    placeholder="Χρήστης..."
                    [formControl]="usrCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                  <perfect-scrollbar>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                      {{ option.firstname }} [ {{ option.email }} ]
                    </mat-option>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-hint align="start"> <strong>Αναζήτση</strong> με χρήστες </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="adminView" class="kt-form__filtration">
          <div class="row align-items-center">
            <div class="col-md-12 kt-margin-bottom-10-mobile">
              <mat-form-field class="mat-form-field-fluid">
                <mat-chip-list #_chipList aria-label="Επιλογή χρηστών">
                  <mat-chip
                    class="fchip"
                    *ngFor="let usr of fabUsers"
                    [selectable]="false"
                    [removable]="true"
                    (removed)="_ab_remove(usr)"
                  >
                    {{ usr.lastname }} {{ usr.firstname }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #_usrInput
                    (keyup)="_ab_filterUsers($event)"
                    placeholder="Εκτός από Χρήστες..."
                    [formControl]="_usrCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="_chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  />
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="_ab_selected($event)">
                  <perfect-scrollbar>
                    <mat-option *ngFor="let option of _ab_filteredOptions | async" [value]="option">
                      {{ option.id }}/{{ option.kap }} {{ option.lastname }}
                      {{ option.firstname }} [ {{ option.email }} ]
                    </mat-option>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-hint align="start"> <strong>Αναζήτση εκτός από</strong> χρήστες </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <!-- end::FILTERS -->

      <!-- start::GROUP ACTIONS -->
      <!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
      <!-- Group actions are shared for all LISTS -->
      <div
        class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: selection.selected.length > 0 }"
      >
        <!-- We show 'Group Actions' div if smth are selected -->
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline">
            <div class="kt-form__label kt-form__label-no-wrap">
              <label class="kt--font-bold kt-font-danger-">
                <span>Επιλεγμένες εγγραφές: </span> {{ selection.selected.length }}
              </label>
              <!-- selectedCountsTitle => function from codeBehind (users-list.component.ts file) -->
              <!-- selectedCountsTitle => just returns title of selected items count -->
              <!-- for example: Selected records count: 4 -->
            </div>
            <div class="kt-form__control kt-form__group--inline">
              <button
                (click)="deleteShipments()"
                color="warn"
                mat-raised-button
                matTooltip="Διαγραφή επιλεγμένων αποστολών"
                class="del-btn-l mat-button-mt-4"
              >
                <mat-icon>delete</mat-icon>
                Διαγραφή Επιλεγμένων
              </button>
              <button
                (click)="approveShipments()"
                mat-raised-button
                matTooltip="Έγκριση επιλεγμένων αποστολών."
                class="approve-btn-l mat-button-mt-4"
              >
                <mat-icon>thumb_up</mat-icon>
                Έγκριση Επιλεγμένων
              </button>
              <button
                (click)="printVouchers()"
                mat-raised-button
                matTooltip="Εκτύπωση Voucher για τις επιλεγμένες αποστολές"
                class="print-btn-l mat-button-mt-4"
              >
                <mat-icon>local_printshop</mat-icon>
                Εκτύπωση Voucher
              </button>
              <button
                (click)="genXLS()"
                mat-raised-button
                matTooltip="Δημιουργία Excel από τις επιλεγμένες"
                class="xls-btn-l mat-button-mt-4"
              >
                <i class="fas fa-file-excel xls-i"></i>
                Δημιουργία Excel
              </button>
              <button
                (click)="massMarkReady()"
                mat-raised-button
                matTooltip="Μαρκάρισμα επιλεγμένων αποστολών ως έτοιμες προς αποστολή"
                class="mr-btn-l mat-button-mt-4"
              >
                <i class="fas fa-paper-plane"></i>
                Μαρκάρισμα ως Έτοιμες
              </button>
              <button
                (click)="massUnMarkReady()"
                mat-raised-button
                matTooltip="Μαρκάρισμα επιλεγμένων αποστολών ως μη έτοιμες προς αποστολή"
                class="umr-btn-l mat-button-mt-4"
              >
                <i class="fas fa-paper-plane"></i>
                Μαρκάρισμα ως μη Έτοιμες
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end::GROUP ACTIONS -->
    </div>
    <!-- end::FILTERS & GROUP ACTIONS -->

    <!-- MATERIAL TABLE | Binded to datasources -->
    <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
    <div class="mat-table__bottom">
      <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
      <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
      <!-- MATERIAL PAGINATOR | Binded to dasources -->
      <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
      <mat-paginator
        [pageSize]="paginator.pageSize"
        [pageSizeOptions]="[10, 50, 100, 200, 500]"
        [length]="dataSource.paginatorTotal$ | async"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
    <div class="mat-table__wrapper" [class.adm-view]="adminView">
      <mat-table
        class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        [matSortActive]="sort.active"
        [matSortDirection]="sort.direction"
        matSortDisableClear
      >
        <!-- Checkbox Column -->

        <!-- Table with selection -->
        <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="mat-column-checkbox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef>Χρήστης</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span *ngIf="shipment.user | lookupUser | async as usr">
              <a
                routerLink="/user-management/users/edit/{{ usr.id }}"
                *ngIf="!usr.is_company"
                matTooltip="
                                           ID: {{ usr.id }}&#13;
                                           Email: {{ usr.email }}&#13;
                                           Τηλ: {{ usr.phone }}&#13;
                                           Κιν: {{ usr.mobile }}&#13;
                                           ΑΦΜ: {{ usr.vat }}&#13;
                                           ΑΔΤ: {{ usr.adt }}
                                           "
                [matTooltipClass]="'brk-tooltip'"
                >{{ usr.lastname }} {{ usr.firstname }}
              </a>
              <a
                routerLink="/user-management/users/edit/{{ usr.id }}"
                *ngIf="usr.is_company"
                matTooltip="
                                           ID: {{ usr.id }}&#13;
                                           Email: {{ usr.email }}&#13;
                                           Τηλ: {{ usr.phone }}&#13;
                                           Κιν: {{ usr.mobile }}&#13;
                                           Εταιρία: {{ usr.companyName }}&#13;
                                           ΑΦΜ Εταιρίας: {{ usr.companyVat }}&#13;
                                           ΔΟΥ: {{ usr.companyFiscalService }}
                                           "
                [matTooltipClass]="'brk-tooltip'"
                >{{ usr.lastname }} {{ usr.firstname }}
              </a>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="recipient_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Παραλήπτης</mat-header-cell>
          <mat-cell *matCellDef="let shipment"> {{ shipment.recipient_name }} </mat-cell>
        </ng-container>

        <ng-container *ngIf="!adminView" matColumnDef="date_created">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Δημιουργήθηκε</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.date_created | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_approved">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Εγκρίθηκε</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.date_approved | date }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="cod_ammount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Αντ/βολή</mat-header-cell>
          <mat-cell *matCellDef="let shipment"
            ><span class="cod" [ngClass]="{ sout: shipment.is_returned }">{{
              shipment.cod_ammount | currency: "EUR"
            }}</span></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="_status">
          <mat-header-cell *matHeaderCellDef>Κατάσταση</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <div *ngIf="shipment._status === 'PENDING'" class="pending">
              <span class="status-icon"><i class="flaticon2-download"></i></span>
              <span class="status-text">Εκκρεμεί</span>
            </div>
            <div *ngIf="shipment._status === 'PROCESSED'" class="approved">
              <span class="status-icon"
                ><i class="flaticon2-black-back-closed-envelope-shape"></i
              ></span>
              <span class="status-text">Εγκρίθηκε</span>
            </div>
            <div *ngIf="shipment._status === 'PAID' && adminView" class="paid">
              <span class="status-icon"><i class="fas fa-money-check-alt"></i></span>
              <span class="status-text">Εισπραγμένη</span>
            </div>
            <div *ngIf="shipment._status === 'PAID' && !adminView" class="approved">
              <span class="status-icon"
                ><i class="flaticon2-black-back-closed-envelope-shape"></i
              ></span>
              <span class="status-text">Εγκρίθηκε</span>
            </div>
            <div *ngIf="shipment._status === 'PAID_APPROVED'" class="paida">
              <span class="status-icon"><i class="fas fa-hand-holding-usd"></i></span>
              <span class="status-text">Πρός Απόδ.</span>
            </div>
            <div *ngIf="shipment._status === 'INVOICED'" class="inv">
              <span class="status-icon"><i class="fas fa-file-invoice-dollar"></i></span>
              <span class="status-text">Εξοφλημένη</span>
            </div>
            <div *ngIf="shipment._status === 'RETURNED'" class="returned">
              <span class="status-icon"><i class="fas fa-undo-alt"></i></span>
              <span class="status-text">Επεστραμένη</span>
            </div>
            <div *ngIf="shipment._status === 'CANCELLATION_PENDING'" class="cancellation_pending">
              <span class="status-icon"><i class="fas fa-exclamation-circle"></i></span>
              <span class="status-text">Προς Ακύρ.</span>
            </div>
            <div *ngIf="shipment._status === 'CANCELLED'" class="cancelled">
              <span class="status-icon"><i class="fas fa-times-circle"></i></span>
              <span class="status-text">Ακυρωμένη</span>
            </div>
            <div *ngIf="shipment._status === 'CLARIFICATION'" class="cancelled">
              <span class="status-icon"><i class="fas fa-question"></i></span>
              <span class="status-text">Διασάφηση</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vg_code">
          <mat-header-cell *matHeaderCellDef>Κωδ. Αποστολής</mat-header-cell>
          <mat-cell *matCellDef="let shipment">{{ shipment.vg_code }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="is_ready">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Έτοιμη</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span *ngIf="shipment.is_ready" class="ready"><i class="fas fa-check"></i></span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="is_delivered">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Παρ/μένη</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <span *ngIf="shipment.is_delivered" class="gready"><i class="fas fa-check"></i></span>
          </mat-cell>
        </ng-container>

        <ng-container
          *ngIf="{ pickup_rights: (user$ | async)?.pickup_rights } as user"
          matColumnDef="actions"
        >
          <mat-header-cell *matHeaderCellDef>Ενέργειες</mat-header-cell>
          <mat-cell *matCellDef="let shipment">
            <button
              *ngIf="shipment._status !== 'PENDING'"
              (click)="viewShipment(shipment)"
              mat-icon-button
              color="primary"
              matTooltip="Λεπτομέρειες Αποστολής"
            >
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button
              *ngIf="
                shipment._status !== 'PENDING' &&
                shipment._status !== 'CANCELLATION_PENDING' &&
                shipment._status !== 'CANCELLED' &&
                shipment.courier !== 'GENIKI'
              "
              (click)="viewVoucher(shipment)"
              mat-icon-button
              color="warn"
              matTooltip="Προβολή ΣΥ.ΔΕ.ΤΑ."
            >
              <mat-icon>picture_as_pdf</mat-icon>
            </button>
            <button
              *ngIf="
                shipment._status !== 'PENDING' &&
                shipment._status !== 'CANCELLATION_PENDING' &&
                shipment._status !== 'CANCELLED' &&
                shipment.courier !== 'GENIKI'
              "
              mat-icon-button
              color="secondary"
              matTooltip="Εκτύπωση ΣΥ.ΔΕ.ΤΑ"
              class="print-btn"
              type="button"
              (click)="printVoucher(shipment)"
            >
              <mat-icon>local_printshop</mat-icon>
            </button>
            <button
              *ngIf="shipment._status === 'PENDING' || adminView"
              (click)="editShipment(shipment.id)"
              mat-icon-button
              color="primary"
              matTooltip="Επεξεργασία Αποστολής"
            >
              <mat-icon>create</mat-icon>
            </button>
            <button
              *ngIf="shipment._status === 'PENDING' && shipment.courier !== 'GENIKI'"
              (click)="approveShipment(shipment)"
              mat-icon-button
              color="primary"
              class="approve-btn"
              matTooltip="Έγκριση Αποστολής"
            >
              <mat-icon>thumb_up</mat-icon>
            </button>
            <button
              *ngIf="shipment._status === 'PENDING' || adminView"
              mat-icon-button
              color="warn"
              matTooltip="Διαγραφή Αποστολής"
              type="button"
              (click)="deleteShipment(shipment)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <button
              *ngIf="shipment._status === 'PENDING' && !shipment.is_ready"
              mat-icon-button
              class="mark_ready-btn"
              matTooltip="Μαρκάρισμα ως Έτοιμη"
              type="button"
              (click)="markReady(shipment)"
            >
              <i class="fas fa-paper-plane"></i>
            </button>
            <button
              *ngIf="shipment._status === 'PENDING' && shipment.is_ready"
              mat-icon-button
              class="unmark_ready-btn"
              matTooltip="Μαρκάρισμα ως μη Έτοιμη"
              type="button"
              (click)="unmarkReady(shipment)"
            >
              <i class="fas fa-paper-plane"></i>
            </button>
            <button
              *ngIf="
                !['PENDING', 'CANCELLED', 'CANCELLATION_PENDING', 'RETURNED'].includes(
                  shipment._status
                ) &&
                shipment.is_delivered &&
                !shipment.is_return &&
                !shipment.is_returned &&
                !shipment.return_shipment &&
                shipment.courier !== 'GENIKI'
              "
              mat-icon-button
              class="mark_ready-btn"
              matTooltip="Δημιουργία Επιστροφής"
              type="button"
              (click)="createReturn(shipment)"
            >
              <i class="fas fa-share-square"></i>
            </button>
            <button
              *ngIf="shipment.is_dsr && shipment.return_of"
              (click)="viewShipment(shipment.return_of)"
              mat-icon-button
              class="orig-dsr"
              matTooltip="Αρχική Αποστολή"
            >
              <i class="fas fa-mail-bulk"></i>
            </button>
            <button
              *ngIf="shipment.return_shipment"
              (click)="viewShipment(shipment.return_shipment)"
              mat-icon-button
              class="return-dsr"
              matTooltip="Επιστροφική Αποστολή"
            >
              <i class="fas fa-mail-bulk"></i>
            </button>
            <button
              *ngIf="
                shipment._status == 'PROCESSED' &&
                ['SPEEDEX'].includes(shipment.courier) &&
                (user.pickup_rights == 2 ||
                  (user.pickup_rights == 1 && shipment.is_dsr) ||
                  adminView)
              "
              (click)="viewPickupOrder(shipment)"
              mat-icon-button
              class="pickup-order"
              matTooltip="Εντολή Παραλαβής"
            >
              <i class="fas fa-shipping-fast"></i>
            </button>
            <!--button *ngIf="shipment._status == 'PROCESSED' && ['SPEEDEX'].includes(shipment.courier) && !shipment.is_delivered && adminView" (click)="markPending(shipment)" mat-icon-button color="warn" matTooltip="Αλλαγή κατάστασης σε Εκκρεμεί">
                            <i class="fas fa-skull-crossbones"></i>
                        </button-->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ is_dsr: row.is_dsr }"
        ></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
      <!-- Message for empty data  -->
      <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">
        Please wait....
      </div>
    </div>

    <div id="pdf_js">
      <ng2-pdfjs-viewer
        #pdfViewerOnDemand
        [startPrint]="true"
        [openFile]="false"
        [download]="false"
      ></ng2-pdfjs-viewer>
    </div>
  </kt-portlet-body>
  <!-- end::Body -->
</kt-portlet>
